var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row "},[_c('div',{staticClass:"small-8 columns with-right-space "},[(_vm.hasConnectedHooks)?_c('table',{staticClass:"woot-table"},[_c('thead',[_vm._l((_vm.hookHeaders),function(hookHeader){return _c('th',{key:hookHeader},[_vm._v("\n          "+_vm._s(hookHeader)+"\n        ")])}),_vm._v(" "),(_vm.isHookTypeInbox)?_c('th',[_vm._v("\n          "+_vm._s(_vm.$t('INTEGRATION_APPS.LIST.INBOX'))+"\n        ")]):_vm._e()],2),_vm._v(" "),_c('tbody',_vm._l((_vm.hooks),function(hook){return _c('tr',{key:hook.id},[_vm._l((hook.properties),function(property){return _c('td',{key:property,staticClass:"hook-item"},[_vm._v("\n            "+_vm._s(property)+"\n          ")])}),_vm._v(" "),(_vm.isHookTypeInbox)?_c('td',{staticClass:"hook-item"},[_vm._v("\n            "+_vm._s(_vm.inboxName(hook))+"\n          ")]):_vm._e(),_vm._v(" "),_c('td',{staticClass:"button-wrapper"},[_c('woot-button',{attrs:{"variant":"link","color-scheme":"secondary","icon":"ion-close-circled","class-names":"grey-btn"},on:{"click":function($event){return _vm.$emit('delete', hook)}}},[_vm._v("\n              "+_vm._s(_vm.$t('INTEGRATION_APPS.LIST.DELETE.BUTTON_TEXT'))+"\n            ")])],1)],2)}),0)]):_c('p',{staticClass:"no-items-error-message"},[_vm._v("\n      "+_vm._s(_vm.$t('INTEGRATION_APPS.NO_HOOK_CONFIGURED', {
          integrationId: _vm.integration.id,
        }))+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"small-4 columns"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.integration.name))])]),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t(
          ("INTEGRATION_APPS.SIDEBAR_DESCRIPTION." + (_vm.integration.name.toUpperCase())),
          { installationName: _vm.globalConfig.installationName }
        )
      )}})])])}
var staticRenderFns = []

export { render, staticRenderFns }